import { faGlobe, faPlusCircle, faCompass } from '@fortawesome/free-solid-svg-icons';
import * as pics from '../assets/index' ;
import * as pic from '../assets/index';

export const carouselData = [
    {
      title: 'Smart Home',
      des: 'GrandeurSmart is a Smart Home Automation company, That focuses on Automating securing of homes, City and Community with a wide range of smart home devices from any manufacturer.',
      icon: faGlobe,
      buttonTitle: 'Explore Product',
      link: 'https://grandeursmart.com/',
      image: `${pics.appNarration}`,
    },
    {
      title: 'Health and Elderly care IOT.',
      des: 'With our Smart Health and Elderly care kit, we provide remote monitoring, prevention of disease, Diet Balancing, Medical Habits like taking of drugs at right time, Walking speed and Temperature measurement for Elderly ones and patients',
      icon: faPlusCircle,
      buttonTitle: 'Learn More',
      link: 'https://grandeursmart.com/products',
      image: `${pics.healthCare}`,
    },
    {
      title: 'Pet IoT Solutions',
      // des: 'Our Pet IoT Solutions are crafted to ensure the health and safety of your beloved pets through advanced monitoring and analytics.',
      des: 'Empowering Pet Owners with Smart IoT Solutions for Health, Monitoring, Tracking, and Wellness – Keeping Your Furry Friend Safe, Happy, and Healthy Anytime, Anywhere!',
      icon: faCompass,
      buttonTitle: 'Explore',
      link: '/pets-iot',
      image: `${pics.petMain}`,
    },
    {
      title: 'Mainlogix IOT Edge Gateway',
      des: 'The Mainlogix IoT Edge Gateway is a robust, customizable solution designed for developers and innovators to manage IoT devices across diverse industries, offering reliable connectivity and seamless integration through support for multiple wireless and wired protocols.',
      icon: faGlobe,
      buttonTitle: 'Explore',
      link: '/edge-gateway',
      image: `${pics.gateway}`,
    },
  ];



export const servicesData = [
  {
    id: 1,
    title: "IoT App Development",
    des: "Crafting intelligent, user-centric applications that seamlessly connect devices, streamline operations, and unlock the full potential of your IoT ecosystem.",
    link: "/iot-services",
    image: `${pics.service1}`,
  },
  {
    id: 2,
    title: "Consulting Services",
    des: "Providing expert guidance to navigate the complexities of IoT, ensuring strategic alignment, optimized performance, and successful implementation of your initiatives.",
    link: "/consulting-services",
    image: `${pics.service2}`,
  },
  {
    id: 3,
    title: "IoT Managed Services",
    des: "Delivering end-to-end management and support for your IoT infrastructure, ensuring continuous operation, security, and scalability with expert oversight.",
    link: "/iot-managed-services",
    image: `${pics.service3}`,
  },
  {
    id: 4,
    title: "IoT Data Science & Analytics",
    des: "Transforming raw IoT data into actionable insights, driving informed decision-making and optimizing performance through advanced analytics and machine learning.",
    link: "/iot-data-and-analytics",
    image: `${pics.service4}`,
  },
  {
    id: 5,
    title: "AIOT Services",
    des: "Combine AI with IoT to develop intelligent, interconnected systems for a variety of applications.",
    link: "/aiot",
    image: `${pics.consultService3}`,
  },
  {
    id: 6,
    title: "IOT Use Cases",
    des: "Real-world applications of IoT technology, showcasing how connected devices are transforming industries, enhancing efficiency, and driving innovation across different sectors.",
    link: "/iot-use-cases",
    image: `${pics.service5}`,
  },
  {
    id: 7,
    title: "IoT Machine Learning Services",
    des: "Harnessing the power of machine learning to enhance your IoT solutions, enabling predictive analytics, intelligent automation, and smarter decision-making.",
    link: "/iot-machine-learning-services",
    image: `${pics.service6}`,
  },
  
];



  export const productData = [
    
    {
      id: 1,
      name: 'Humidity Sensor',
      description1: [
        'Our Humidity Sensor provides accurate and reliable monitoring of humidity levels, ensuring optimal conditions for your environment. Perfect for home automation, agricultural applications, and industrial environments. The sensor is designed to deliver consistent and precise data, allowing for automated control systems to adjust humidity levels as needed.',
        
      ],
      description2: [
        'Real-Time Humidity Monitoring: Continuously tracks humidity levels in real-time.',
        'Automated Alerts: Receive notifications if humidity levels go beyond set thresholds.',
        'Integration with Smart Systems: Easily integrate with home automation or industrial control systems.',
        'Low Power Consumption: Efficient power usage ensures long battery life.',
        'Durability: Built to withstand various environmental conditions for long-term use.'
      ],
      benefitDes: "Our Humidity Sensor offers numerous benefits for maintaining and optimizing your environment. Key advantages include:",
      benefits: [
        ['Reliable Monitoring', 'Provides accurate and consistent humidity data to ensure your environment remains within optimal conditions, whether for home comfort, agricultural productivity, or industrial processes, thereby enhancing overall efficiency and safety.'],
        ['Automation Ready', 'Easily integrates with automation systems, enabling smart control of HVAC systems, humidifiers, or dehumidifiers, leading to energy savings and maintaining a stable environment without manual intervention.'],
        ['Cost-Effective', 'Long-lasting and energy-efficient design reduces the need for frequent replacements or maintenance, saving you money in the long run while providing continuous, reliable performance in any setting.'],
        ['Versatile Application', 'Ideal for a wide range of applications, from residential use in smart homes to commercial and industrial environments, offering flexibility in how and where it can be deployed.']
      ],
      images: [
        `${pics.temp1}`,
        `${pics.temp3}`,
        `${pics.temp2}`,
        `${pics.temp1}`,
      ],
      amount: '$120',
    },


    {
      id: 2,
      name: 'Smart Lock',
      description1: [
        'The Smart Lock provides a seamless and secure way to control access to your home or office. It allows for keyless entry, remote access, and integrates with other smart home devices for enhanced security.',
        
      ],
      description2: [
        'Remote Access: Lock or unlock your door from anywhere using your smartphone.',
        'Auto-Locking: Set the lock to automatically engage after a set time.',
        'Voice Control: Works with voice assistants like Alexa and Google Assistant.',
        'Tamper Alerts: Receive instant notifications if someone tries to tamper with the lock.',
        'Easy Installation: Simple to install without the need for professional assistance.'
      ],
      benefitDes: "Our Smart Lock provides unparalleled convenience and security for modern homes and offices. Key benefits include:",
      benefits: [
        ['Enhanced Security', 'Offers advanced encryption and multi-layered security protocols to protect against unauthorized access, ensuring your property remains safe and secure at all times, whether you are home or away.'],
        ['Convenience', 'Keyless entry and remote access eliminate the need for traditional keys, providing a hassle-free way to enter your home or office, which is especially useful for busy lifestyles.'],
        ['Integration with Smart Home Systems', 'Seamlessly integrates with existing smart home ecosystems, allowing you to create automated routines and control multiple devices from a single app, enhancing the overall functionality of your smart home.'],
        ['User-Friendly Design', 'The lock is easy to install and use, with intuitive controls and a user-friendly app interface, making it accessible to a wide range of users, including those who may not be tech-savvy.']
      ],
      images: [
        `${pics.smartLock1}`,
        `${pics.smartLock2}`,
        `${pics.smartLock3}`,
        `${pics.smartLock1}`,
      ],
      amount: '$120',
    },


    {
      id: 3,
      name: 'Gateway',
      description1: [
        'Our Gateway serves as a central hub for connecting multiple IoT devices, enabling seamless communication and control from a single interface. It is essential for creating an interconnected smart environment. Designed for ease of use, it allows for quick setup and management of various IoT devices across different platforms.',
        
      ],
      description2: [
        'Multi-Device Connectivity: Supports connection of various IoT devices, including sensors, cameras, and smart appliances.',
        'Secure Data Transmission: Ensures encrypted communication between devices and the network.',
        'Remote Access: Control your devices from anywhere via a mobile app or web interface.',
        'Energy Efficiency: Optimized for low power consumption, reducing overall energy usage.',
        'Scalability: Easily expand your IoT network by adding more devices without performance degradation.'
      ],
      benefitDes: "The Gateway provides a powerful solution for managing and scaling your IoT ecosystem. Key benefits include:",
      benefits: [
        ['Centralized Control', 'Allows for seamless management of all connected devices from a single point, simplifying the operation and monitoring of your smart home or industrial IoT setup, leading to greater efficiency.'],
        ['Enhanced Security', 'Offers robust encryption and security protocols to protect data transmission, ensuring that your IoT network remains secure from potential cyber threats and unauthorized access.'],
        ['Scalability', 'Easily accommodates additional devices without compromising performance, making it ideal for growing IoT networks in both residential and commercial settings, supporting future expansion.'],
        ['Energy Efficient', 'Designed with energy-saving features that minimize power consumption while maintaining optimal performance, contributing to lower operational costs and a more sustainable environment.']
      ],
      images: [
        `${pics.gateway1}`,
        `${pics.gateway2}`,
        `${pics.gateway3}`,
        `${pics.gateway4}`,
      ],
      amount: '$120',
    },


    {
      id: 4,
      name: 'Smart Mirror',
      description1: [
        'The Smart Mirror combines traditional functionality with modern technology, providing an interactive display that offers real-time information, personalized content, and home automation control. It serves as both a mirror and a digital assistant, enhancing your daily routines with smart features.',
      ],
      description2: [
        'Interactive Display: Provides real-time weather updates, calendar reminders, and news headlines.',
        'Voice Control: Integrates with voice assistants for hands-free operation.',
        'Customizable Interface: Personalize the display to show the information you need most.',
        'Home Automation Hub: Control smart home devices directly from the mirror.',
        'High-Quality Reflection: Functions as a traditional mirror when not in use.'
      ],
      benefitDes: "Our Smart Mirror offers a unique blend of style and technology, bringing the future of home automation to your living space. Key benefits include:",
      benefits: [
        ['Convenient Access to Information', 'Displays real-time data such as weather forecasts, calendar events, and news headlines, helping you stay informed and organized without needing to check separate devices.'],
        ['Home Automation Integration', 'Functions as a central hub for controlling other smart home devices, allowing you to manage lighting, thermostats, and security systems with voice commands or through the mirror’s interface.'],
        ['Personalization', 'Offers customizable display options so you can tailor the information and features to suit your preferences, providing a more personalized and user-friendly experience every day.'],
        ['Stylish Design', 'The sleek and modern design complements any room décor while adding cutting-edge functionality, making it a perfect addition to bedrooms, bathrooms, or entryways.']
      ],
      images: [
        `${pics.smartMirror1}`,
        `${pics.smartMirror2}`,
        `${pics.smartMirror3}`,
        `${pics.smartMirror4}`,
      ],
      amount: '$120',
    },


    {
      id: 5,
      name: 'Intelligent Camera',
      description1: [
        'Experience advanced surveillance and monitoring with our AI cameras, equipped with intelligent features for enhanced security and analysis.',
      ],
      description2: [
        'AI-Powered Analytics: Real-time facial recognition, motion detection, and object tracking.',
        'High-Resolution Imaging: Crisp and clear video quality, even in low-light conditions.',
        'Smart Alerts: Instant notifications for suspicious activity or predefined events.',
        'Cloud Storage: Secure cloud storage options for easy access and retrieval.',
        'Integration with Smart Home Systems: Seamless integration with existing smart home devices and systems.'
      ],
      benefitDes: "Our RTOS development services focus on delivering high-performance, real-time solutions for wearable and IoT devices. Benefits include:",
      benefits: [
        ['Enhanced security and monitoring capabilities', 'Advanced systems provide comprehensive coverage with high-resolution cameras, night vision, and motion detection, ensuring that all areas are monitored effectively and any suspicious activity is captured clearly.'],
        ['Real-Time insights and proactive alerts', 'These systems offer real-time data and insights, enabling users to respond promptly to potential security threats. Proactive alerts via notifications or alarms help in taking immediate action to prevent incidents.'],
        ['Convenient access to footage from anywhere', 'With remote access features, users can view live or recorded footage from any location using smartphones, tablets, or computers. This convenience ensures that users can monitor their property or business on the go, enhancing overall security management.'],
        
      ],
      images: [
        `${pics.smartCam1}`,
        `${pics.smartCam2}`,
        `${pics.smartCam3}`,
        `${pics.smartCam4}`,
      ],
      amount: '$120',
    },


    {
      id: 6,
      name: 'Key Tracking Device',
      description1: [
        'Our Key Tracking Device ensures you never lose your keys again by providing real-time tracking and easy retrieval. Ideal for busy individuals who frequently misplace their keys.',
        'With its compact design and advanced tracking technology, you can quickly locate your keys using your smartphone or tablet.'
      ],
      description2: [
        'Real-Time Tracking: Locate your keys in real-time through the mobile app.',
        'Separation Alerts: Get notified if you leave your keys behind.',
        'Long Battery Life: Enjoy extended usage with a battery that lasts for months.',
        'Compact Design: Small and lightweight, easily attaches to keyrings without adding bulk.',
        'Bluetooth Connectivity: Reliable and secure connection with your smartphone for quick access.'
      ],
      benefitDes: "Our Key Tracking Device provides a simple yet powerful solution for keeping track of your keys. Key benefits include:",
      benefits: [
        ['Quick Retrieval', 'The real-time tracking feature enables you to find your keys instantly, saving time and reducing the frustration associated with losing keys, especially in urgent situations.'],
        ['Peace of Mind', 'Separation alerts notify you if you’ve left your keys behind, ensuring you never have to worry about losing them, providing an extra layer of security and convenience in your daily routine.'],
        ['User-Friendly', 'The device is easy to set up and use, with an intuitive mobile app that allows you to locate your keys with just a few taps, making it accessible to users of all tech skill levels.'],
        ['Durable and Long-Lasting', 'Designed to withstand daily wear and tear, with a long battery life that ensures continuous use without the need for frequent replacements, offering reliability and value.']
      ],
      images: [
        `${pics.keyTracker1}`,
        `${pics.keyTracker2}`,
        `${pics.keyTracker3}`,
        `${pics.keyTracker3}`,
      ],
      amount: '$120',
    },


    {
      id: 7,
      name: 'Smart Adaptor',
      images: [
        `${pics.smartAdaptor1}`,
        `${pics.smartAdaptor2}`,
        `${pics.smartAdaptor3}`,
        `${pics.smartAdaptor4}`,
      ], 
      description1: ["The Smart Adaptor Socket is a versatile device designed to bring smart capabilities to your traditional electrical outlets."],
      description2: [
        "Remote Control: Turn devices on or off from anywhere using a smartphone app.",
        "Voice Control: Compatible with major voice assistants like Alexa, Smart by MainLogix, Google Assistant, and Siri.",
        "Energy Monitoring: Track energy usage of connected devices to optimize energy consumption.",
        "Scheduling: Set timers and schedules for automatic operation of appliances.",
        "Automation: Create custom automation rules to trigger actions based on specific conditions (e.g., turn on a lamp when motion is detected).",
        "Compact Design: Easily plugs into any standard outlet without blocking adjacent sockets.",
        "Safety Features: Built-in surge protection and overload prevention to safeguard your devices."
    ],
      benefitDes: "Experience the convenience and energy efficiency that comes with smart control over your household appliances.",
      benefits: [
          [
              "Remote Control & Automation",
              "Remotely turn devices on or off and create automation rules based on specific conditions, enhancing convenience and security."
          ],
          [
              "Energy Monitoring & Scheduling",
              "Track energy usage and set schedules for appliances to reduce electricity bills and manage energy consumption effectively."
          ],
          [
              "Voice Control Compatibility",
              "Easily control connected devices with voice commands using major assistants like Alexa, Google Assistant, and Siri."
          ],
          [
              "Enhanced Home Security",
              "Automate lights and other devices to enhance home security while you're away."
          ]
      ]
    },
    {
      id: 8,
      name: 'Smart FOB Button',
      images: [
        `${pics.sos1}`,
        `${pics.sos2}`,
        `${pics.sos1}`,
        `${pics.sos2}`,
      ],
      description1: ["The Smart FOB Button is a compact, portable device designed for quick and easy access to a variety of smart home functions."],
      description2: [
        "One-Touch Control: Instantly control smart home devices such as lights, locks, and alarms with a single button press.",
        "Customizable Actions: Program multiple actions or routines to a single button, such as turning off all lights or locking all doors.",
        "Emergency Alerts: Send emergency notifications to designated contacts with a long press and can be used for the Anti Fall system.",
        "Portable Design: Small and lightweight, easy to carry on a keychain or in a pocket.",
        "Long Battery Life: Designed to last for months on a single battery, ensuring reliability.",
        "Durable Construction: Built to withstand daily wear and tear.",
        "Voice Assistant Integration: Compatible with major voice assistants (Smart by MainLogix) for added convenience.",
        "Automation Integration: Trigger complex automation routines, like setting the perfect movie night ambiance with a single press."
      ],
      benefitDes: "Effortlessly manage your smart home devices and routines with the convenience of a single button press.",
      benefits: [
          [
              "One-Touch Control & Customization",
              "Instantly control multiple devices and customize actions or routines for a seamless smart home experience."
          ],
          [
              "Emergency Alerts & Security",
              "Send emergency notifications with a long press, perfect for elderly individuals needing quick access to help."
          ],
          [
              "Portable & Durable Design",
              "Carry it easily on a keychain or in a pocket, with long battery life and a durable build for daily use."
          ],
          [
              "Voice Assistant Integration",
              "Integrates with major voice assistants for added convenience and automation."
          ]
      ]
    },
    {
      id: 9,
      name: 'Attendance System',
      images: [
        `${pics.attendanceSystem1}`,
        `${pics.attendanceSystem2}`,
        `${pics.attendanceSystem3}`,
        `${pics.attendanceSystem1}`,
      ],
      description1: ["Efficient and Secure Employee Attendance Management"],
      description2: [
        "Employee Data Management: Easily input and edit employee names. Manage and update employee records with minimal effort.",
        "Multi-Mode Verification: Palm Recognition, Facial Recognition, Fingerprint Scanning, Card Access, Password Entry. Use any combination of these methods for enhanced security.",
        "Capacity: Supports up to 1000 face records. Stores 1000 fingerprint records with the most competitive pricing. Accommodates up to 3000 fingerprints, making it suitable for various enterprises and companies.",
        "Connectivity: GPRS, 3G, 4G, WiFi: Flexible connectivity options to suit different environments. Backup Battery: Ensures uninterrupted operation during power outages.",
        "Data Management: USB Pen Drive: Easily download or upload reports, TCP/IP or Wireless WiFi: Convenient network options for data transfer. Reports can be generated in Excel or TXT formats.",
        "Software Integration: Free Software: Provided at no additional cost. Free SDK: Integrate our biometric devices with your existing software applications."
      ],
      benefitDes: "Enhance security and streamline attendance management for your organization with this scalable solution.",
      benefits: [
          [
              "Enhanced Security & Verification",
              "Multiple verification methods ensure only authorized personnel gain access, enhancing security."
          ],
          [
              "Scalability for Growing Businesses",
              "Supports a large number of employees, making it suitable for companies of all sizes."
          ],
          [
              "Flexible Connectivity Options",
              "Choose from various connectivity options, ensuring reliability and uninterrupted operation."
          ],
          [
              "Ease of Data Management",
              "Simplify administrative tasks with easy data management and reporting."
          ]
      ]
    },

    {
      id: 10,
      name: 'Door Bell',
      images: [
        `${pics.doorBell}`,
        `${pics.doorBell}`,
        `${pics.doorBell}`,
        `${pics.doorBell}`,
      ],
      description1: ["Enhance Your Home's Security and Convenience"],
      description2: [
        "High-Definition Video: Captures clear, detailed video in 1080p Full HD. Provides a clear view of visitors at your doorstep.",
        "Two-Way Audio: Built-in microphone and speaker. Enables real-time communication with visitors from your smartphone or tablet.",
        "Motion Detection: Advanced sensors detect movement near your door. Sends instant alerts to your mobile device for quick response.",
        "Night Vision: Infrared LEDs provide clear video in low-light conditions. Ensures visibility and security around the clock.",
        "Remote Access: Access live video and recordings from anywhere using a mobile app. Stay connected and monitor your entrance remotely.",
        "Smart Integration: Compatible with popular smart home systems like Alexa, Smart by Mainlogix, and Google Assistant. Allows voice control and seamless integration with other smart devices.",
        "Cloud and Local Storage: Supports cloud storage for secure, off-site backups. Offers local storage options via SD card for easy access to recorded footage.",
        "Easy Installation: Wireless setup with simple mounting options. Quick and hassle-free installation process.",
        "Automated Responses: Customize automatic responses and actions when motion is detected or when the doorbell is pressed. Enhance security and convenience with personalized settings."
      ],
      benefitDes: "Monitor your front door with advanced features that ensure security and convenience at your fingertips.",
      benefits: [
          [
              "Comprehensive Home Security",
              "High-definition video and motion detection provide enhanced security for your home."
          ],
          [
              "24/7 Surveillance & Remote Access",
              "Night vision and remote access ensure continuous monitoring and instant alerts."
          ],
          [
              "Easy Communication & Smart Integration",
              "Speak with visitors and control the doorbell with major voice assistants and smart devices."
          ],
          [
              "Convenient Installation & Storage",
              "Enjoy easy wireless setup and choose between cloud or local storage for your recordings."
          ]
      ]
    },

    {
      id: 11,
      images: [
        `${pics.coSensor}`,
        `${pics.coSensor}`,
        `${pics.coSensor}`,
        `${pics.coSensor}`,
      ],
      name: "Carbon Monoxide (CO) Sensor",
      description1: [
        "Ensure Safety with Reliable CO Detection"
      ],
      description2: [
        "Accurate Detection: Uses advanced electrochemical sensors to detect carbon monoxide levels with high precision. Provides reliable monitoring to ensure the safety of your home.",
        "Real-Time Alerts: Sends instant alerts to your smartphone or tablet when CO levels exceed safe thresholds. Audible alarms within the device ensure immediate awareness.",
        "Smart Integration: Compatible with popular smart home systems like Alexa, Smart by Mainlogix, and Google Assistant. Allows integration with other smart devices for enhanced automation and control.",
        "LED Indicator: Visual LED indicator provides a clear status of CO levels. Changes color to signify normal, warning, and danger levels.",
        "Battery Backup: Equipped with a long-lasting battery backup to ensure continuous monitoring during power outages. Low battery alerts to ensure timely replacement.",
        "Mobile App Access: Monitor CO levels and receive alerts remotely via a dedicated mobile app. View historical data and trends to assess the safety of your environment.",
        "Easy Installation: Simple mounting options for wall or ceiling installation. Wireless setup for hassle-free installation and relocation.",
        "Self-Testing and Maintenance: Automated self-testing feature ensures the sensor is functioning correctly. Alerts for maintenance or sensor replacement to ensure continued reliability."
      ],
      benefitDes: "Experience enhanced safety and peace of mind with our Carbon Monoxide (CO) Sensor, designed to protect you and your loved ones from the dangers of CO poisoning.",
      benefits: [
        ["Enhanced Safety", "Our Carbon Monoxide Sensor provides critical early detection of dangerous CO levels, offering you and your family an extra layer of protection against the potentially deadly effects of carbon monoxide. By detecting this odorless and colorless gas early, it prevents poisoning and ensures a safer living environment, reducing the risk of health issues and providing peace of mind."],
        ["24/7 Monitoring", "With continuous real-time monitoring, our CO Sensor ensures that your home is protected around the clock. It provides instant alerts if CO levels become unsafe, allowing you to take immediate action to address the issue. This constant vigilance helps to safeguard your home from potential hazards, ensuring a constant state of safety and security for you and your loved ones."],
        ["Convenient Integration", "Our sensor integrates seamlessly with other smart home devices, creating a coordinated safety system within your home. By working with systems like Alexa, Smart by Mainlogix, and Google Assistant, it enhances automation and control, allowing you to manage your home environment more effectively. This integration supports enhanced safety measures and streamlined operation of your smart home devices."],
        ["User-Friendly", "Designed with ease of use in mind, our Carbon Monoxide Sensor features clear visual and audible indicators to alert you to CO levels. Its straightforward installation process and intuitive operation ensure that you can set up and use the device without hassle, making it accessible for all users and providing immediate feedback on CO levels within your home."]
      ],
      amount: "29.00"
    },

    {
      id: 12,
      images: [
        `${pics.blind1}`,
        `${pics.blind2}`,
        `${pics.blind3}`,
        `${pics.blind4}`,
      ],
      name: "Curtain & Blind Control",
      description1: [
        "Effortless Control for Ultimate Comfort and Convenience"
      ],
      description2: [
        "Automated Operation: Automatically open and close curtains and blinds based on your schedule or environmental conditions. Customize settings to control the amount of natural light entering your space.",
        "Voice Control: Compatible with popular voice assistants like Alexa, Smart by Mainlogix, Google Assistant, and Siri. Use simple voice commands to open, close, or adjust curtains and blinds.",
        "Mobile App Control: Control curtains and blinds remotely using a dedicated mobile app. Set schedules, adjust settings, and monitor status from anywhere.",
        "Integration with Smart Home Systems: Seamlessly integrates with other smart home devices for coordinated automation. Create scenes and routines that synchronize with lighting, temperature control, and security systems.",
        "Light and Temperature Sensors: Built-in sensors detect changes in light and temperature to automatically adjust curtains and blinds. Helps regulate indoor temperature and reduce energy consumption.",
        "Customizable Schedules: Set specific times for curtains and blinds to open or close. Adapt schedules to match your daily routine or seasonal changes.",
        "Manual Override: Easily override automated settings with manual controls. Use the remote control or the app to adjust curtains and blinds at any time.",
        "Silent Motor Operation: Equipped with a quiet motor to ensure smooth and noiseless operation. Ideal for bedrooms, living rooms, and offices.",
        "Safety Features: Includes obstacle detection to prevent damage or injury. Child-safe design with no exposed cords or chains.",
        "Easy Installation: Simple DIY installation with step-by-step instructions. Compatible with a wide range of curtain and blind types."
      ],
      benefitDes: "Transform your living space with our Curtain & Blind Control system, offering unparalleled convenience and energy efficiency through smart automation.",
      benefits: [
        ["Convenience", "Our Curtain & Blind Control system provides exceptional convenience by allowing you to manage your curtains and blinds effortlessly through voice commands, mobile apps, or automated schedules. This advanced control system ensures that you can adjust your window coverings to suit your preferences and needs, making everyday tasks easier and more enjoyable."],
        ["Energy Efficiency", "Optimize your home's energy efficiency with our Curtain & Blind Control system. By regulating the amount of natural light entering your space and adjusting the blinds based on temperature changes, you can reduce your reliance on heating and cooling systems. This leads to significant savings on energy costs and contributes to a more sustainable lifestyle."],
        ["Enhanced Privacy", "Automatically manage your privacy with our intelligent Curtain & Blind Control system. It ensures that your living spaces remain secure by adjusting curtains and blinds to maintain privacy throughout the day. This feature is especially valuable for homes in busy or densely populated areas, providing you with peace of mind and security."],
        ["Improved Comfort", "Create a more comfortable living environment with our Curtain & Blind Control system. By managing sunlight and glare, you can enhance your indoor comfort levels and create the ideal ambiance for various activities. This system allows you to tailor your environment to your preferences, making your home a more pleasant place to be."]
      ],
      amount: "150.00"
    },

    {
      id: 13,
      images: [
        `${pics.dimmerKnob1}`,
        `${pics.dimmerKnob2}`,
        `${pics.dimmerKnob1}`,
        `${pics.dimmerKnob2}`,
      ],
      name: "Dimmer Knob",
      description1: [
        "Seamless Control for Perfect Lighting Ambiance"
      ],
      description2: [
        "Smooth Dimming: Adjust lighting levels smoothly to create the perfect ambiance for any occasion. Supports both LED and incandescent bulbs for versatile use.",
        "Touch-Sensitive Control: Intuitive touch control for precise dimming adjustments. Easy-to-use interface suitable for all ages.",
        "Voice Control: Compatible with popular voice assistants like Alexa, Smart by Mainlogix, Google Assistant, and Siri. Control lighting levels with simple voice commands for hands-free convenience.",
        "Mobile App Integration: Adjust lighting remotely using the dedicated mobile app. Set schedules, create lighting scenes, and monitor energy usage from anywhere.",
        "Customizable Schedules: Program lighting schedules to match your daily routine. Automatically dim lights at night or brighten them in the morning.",
        "Scene Settings: Create and save personalized lighting scenes for different activities, such as reading, dining, or relaxing. Easily switch between scenes with a tap or voice command.",
        "Energy Efficiency: Optimize energy consumption by dimming lights when full brightness is not needed. Monitor and reduce energy usage through the mobile app.",
        "Retrofit Friendly: Designed to fit standard light switch plates for easy installation. Compatible with most existing home wiring systems.",
        "Manual Override: Provides a physical knob for manual dimming control in addition to touch and app controls. Ensures usability even if smart features are temporarily unavailable.",
        "Safety Features: Includes surge protection and overheating safeguards. Designed with child safety in mind, featuring no exposed electrical components."
      ],
      benefitDes: "Elevate your lighting experience with our Dimmer Knob, offering easy control and energy savings for a more comfortable and personalized home environment.",
      benefits: [
        ["Ambiance Control", "Our Dimmer Knob allows you to effortlessly adjust lighting to create the perfect mood for any room or occasion. Whether you're setting a relaxing atmosphere for an evening at home or brightening up a workspace, the smooth dimming feature ensures you can achieve the ideal lighting conditions to match your needs."],
        ["Convenience", "Control your lighting with ease using our Dimmer Knob, which supports touch, voice commands, and remote adjustments via a mobile app. This multi-faceted control system provides flexibility and convenience, allowing you to manage your lighting effortlessly and ensuring that you can quickly adapt to changing needs or preferences."],
        ["Energy Savings", "By optimizing lighting levels and reducing brightness when full illumination is unnecessary, our Dimmer Knob helps you save on electricity usage. The mobile app integration also allows you to monitor and manage your energy consumption, contributing to overall cost savings and promoting a more energy-efficient home environment."],
        ["Flexibility", "Designed to be compatible with a wide range of light bulbs and existing wiring systems, our Dimmer Knob offers versatile installation options. Its retrofitted design ensures it can be easily integrated into your current setup, making it a practical and adaptable solution for various home lighting needs."]
      ],
      amount: "45.00"
    },
    {
      id: 14,
      name: "Smart Scenes and Switch",
      images: [
        `${pics.sceneSwitch1}`,
        `${pics.dimmerKnob2}`,
        `${pics.sceneSwitch1}`,
        `${pics.dimmerKnob2}`,
      ],
      description1: "Effortless Control for Customized Home Automation. This innovative device allows you to manage your smart home devices with ease.",
      description2: [
        "Customizable Scenes: Create personalized scenes to automate multiple devices with a single command. Examples include 'Morning Routine,' 'Movie Night,' 'Dinner Party,' and 'Away Mode.'",
        "One-Touch Control: Activate pre-set scenes with a single touch on the switch. Simplifies the control of multiple smart devices simultaneously.",
        "Voice Integration: Compatible with Alexa, Smart by MainLogix, Google Assistant, and Siri for voice-activated scene control. Conveniently manage scenes without lifting a finger.",
        "Mobile App Integration: Configure and control scenes from anywhere using the dedicated mobile app. Set schedules, adjust scenes, and monitor device status remotely.",
        "Flexible Scheduling: Automate scenes based on time of day, day of the week, or specific events. Enhances convenience and energy efficiency.",
        "Multi-Device Compatibility: Supports a wide range of smart home devices, including lights, thermostats, blinds, and security systems. Ensures seamless integration with existing smart home ecosystems.",
        "Scene Customization: Tailor scenes to suit your lifestyle, preferences, and routine. Combine lighting, temperature, security, and entertainment settings into one cohesive experience.",
        "Manual Override: Includes a physical switch for manual scene activation and deactivation. Ensures functionality even if smart features are temporarily unavailable.",
        "Energy Efficiency: Optimize energy usage by scheduling scenes that reduce power consumption during low-usage periods. Monitor and adjust energy settings through the mobile app.",
        "Safety Features: Equipped with surge protection and overheating safeguards. Ensures safe and reliable operation."
      ],
      benefitDes: "Discover how the Smart Scenes and Switch can transform your home automation with unparalleled convenience and flexibility.",
      benefits: [
        ["Convenience", "Simplifies the management of multiple devices with one-touch control and voice commands, making it easier to manage your smart home."],
        ["Customization", "Personalizes your smart home experience by creating scenes tailored to your needs, allowing for a more customized environment."],
        ["Energy Savings", "Enhances energy efficiency by automating device usage according to schedules and scenes, helping you save on energy costs."],
        ["Flexibility", "Easily integrates with a variety of smart home devices and systems, providing a seamless experience across your smart home ecosystem."]
      ],
     
      amount: "200.00"
    },
    {
      id: 15,
      name: "Door and Window Sensor",
      images: [
        `${pics.contactSensor1}`,
        `${pics.contactSensor2}`,
        `${pics.contactSensor3}`,
        `${pics.contactSensor4}`,
      ],
      description1: "Enhanced Security and Automation for Your Smart Home. This sensor provides real-time monitoring to keep your home secure.",
      description2: [
        "Open/Closed Detection: Detects whether doors and windows are open or closed in real-time. Provides instant alerts to your smart device when status changes.",
        "Versatile Application: Suitable for all types of doors and windows in homes, offices, and businesses. Ensures comprehensive security coverage.",
        "Wireless Connectivity: Operates wirelessly, connecting seamlessly to your smart home network. Easy installation without the need for complex wiring.",
        "Battery Powered: Long-lasting battery life ensures continuous operation without frequent replacements. Low battery notifications for timely maintenance.",
        "Compact Design: Sleek and compact sensor design blends seamlessly with your home décor. Discreet placement on doors and windows for minimal visibility.",
        "Smart Home Integration: Works with popular smart home platforms like Alexa, Smart by Mainlogix, Google Assistant, and others. Enables voice control and integration with other smart devices.",
        "Automation Capabilities: Automate connected devices based on door/window status changes. Create scenarios such as automatically turning on lights when a door opens.",
        "Security Alerts: Receive instant notifications on your smartphone for unauthorized access attempts. Enhances home security and peace of mind.",
        "Tamper Detection: Alerts you if someone attempts to tamper with the sensor. Ensures reliable security monitoring.",
        "Remote Monitoring: Check the status of doors and windows remotely through a dedicated mobile app. Monitor your home’s security from anywhere."
      ],
      benefitDes: "Enhance your home security and convenience with the Door and Window Sensor, designed to keep you informed and secure.",
      benefits: [
        ["Enhanced Security", "Monitor access points and receive alerts for enhanced home protection, ensuring you are always aware of any unauthorized entry."],
        ["Convenience", "Automate smart home devices based on door/window status changes, streamlining your daily routines and enhancing ease of use."],
        ["Integration", "Seamlessly integrates with existing smart home ecosystems, providing a unified approach to home security and automation."],
        ["Energy Efficiency", "Improve energy efficiency by automating heating/cooling systems based on door/window status, helping you save on energy costs."]
      ],
      amount: "9.00"
    },

    {
      id: 16,
      name: "Gas Sensor",
      images: [
        `${pics.gasSensor1}`,
        `${pics.gasSensor2}`,
        `${pics.gasSensor1}`,
        `${pics.gasSensor2}`,
      ],
      description1: "Enhanced Safety for Your Home. Ensure your home’s safety with this advanced gas detection sensor.",
      description2: [
        "Gas Detection: Detects the presence of hazardous gases such as natural gas (methane), propane, butane, and carbon monoxide (CO). Provides real-time monitoring to ensure early detection of leaks.",
        "Alarm Notifications: Sounds an audible alarm and sends instant notifications to your smartphone upon detecting gas leaks. Alerts you to take immediate action to prevent potential hazards.",
        "Versatile Placement: Suitable for installation in kitchens, utility rooms, basements, and other areas prone to gas leaks. Compact design allows for discreet placement.",
        "Continuous Monitoring: Monitors gas levels continuously, providing ongoing protection day and night. Ensures round-the-clock safety for your household.",
        "Smart Home Integration: Integrates with popular smart home platforms such as Alexa, Smart by MainLogix, Google Assistant, and others. Enables voice commands and automation with other smart devices.",
        "Remote Access and Control: Check gas levels and receive alerts remotely via a dedicated mobile app. Take action from anywhere to ensure the safety of your home.",
        "Easy Installation: Simple DIY installation with included mounting hardware and instructions. No professional wiring required, operates on battery or mains power.",
        "Low Maintenance: Long lifespan with minimal maintenance required. Low battery notifications ensure continuous operation.",
        "Emergency Response Capability: Provides critical information to emergency services in case of a gas leak. Enhances emergency response times for quick mitigation."
      ],
      benefitDes: "The Gas Sensor provides essential safety features, ensuring your home is protected from dangerous gas leaks and offering peace of mind.",
      benefits: [
        ["Safety Assurance", "Early detection of gas leaks to prevent potential hazards and ensure the safety of your family, offering crucial protection against gas-related incidents."],
        ["Convenience", "Receive real-time alerts on your smartphone for prompt action, even when you're away from home, ensuring you're always informed about potential dangers."],
        ["Integration", "Seamlessly integrates into your smart home ecosystem for enhanced automation and control, allowing you to manage safety features easily."],
        ["Peace of Mind", "Continuous monitoring provides peace of mind knowing your home is protected around the clock from hazardous gas leaks."]
      ],
     
      amount: "22.00"
    },

    // {
    //   id: 4,
    //   name: 'Smart Mirror',
    //   description1: ['Our advanced tracking devices ensure the security and real-time location tracking of your valuables, vehicles, and bicycles.', 
    //     'other description if available'
    //   ],
	  //   description2: ['Real-Time Location Tracking: Precise GPS tracking for keys, cars, and bicycles.', 
    //     'Real-Time Location Tracking: Precise GPS tracking for keys, cars, and bicycles.', 
    //     'Battery Life: Long-lasting battery with power-saving modes.', 
    //     'Mobile App Integration: Easily track and manage devices via our mobile app.', 
    //     'Anti-Theft Alarms: Immediate alerts in case of unauthorized movement',
    //   ],
    //   benefitDes: "Our RTOS development services focus on delivering high-performance, real-time solutions for wearable and IoT devices. Benefits include:",
    //   benefits: [
    //     ['Benefit1 heading', 'Benefit1 Description'],
    //     ['Benefit2 heading', 'Benefit2 Description'],
    //     ['Benefit3 heading', 'Benefit3 Description'],
    //     ['Benefit4 heading', 'Benefit4 Description']
    //   ],
    //   images: [
    //     `${pics.smartMirror1}`,
    //     `${pics.smartMirror2}`,
    //     `${pics.smartMirror3}`,
    //     `${pics.smartMirror4}`,
    //   ],
    //   amount: '$120',
    // },
  ];

  export const iotServicesData = [
    {
      ourExpertise: [
        ['Apple Watch:', 'Designing tailored apps for the Apple Watch that make it easier for users to interact with their devices and surroundings.'],
        ['Bluetooth:', 'Using Bluetooth technology to build apps that improve communication between devices, making connectivity and data sharing more seamless.'],
        ['RFID:', 'Developing RFID-based applications for tracking, identification, and data collection in a variety of industries.'],
        ['Sensors:', 'Integrating sensors into our apps to gather environmental data, enabling real-time insights and automation.'],
        ['Raspberry Pi:', 'Creating innovative applications on Raspberry Pi that enhance device communication using Bluetooth.'],
        ['Beacons:', 'Developing location-based apps with beacon technology to deliver personalized experiences and proximity-based interactions.'],
      ],
      
    },
  ]

  export const IotDataAnalyticsServices = [
    {
      ourServices: [
        ['Real-Time Data Collection:', 'Get up-to-the-minute information from your IoT devices, ensuring you’re always in the loop.'],
        ['Advanced Analytics:', 'Our AI-driven platform analyzes data to spot trends and predict potential issues before they happen.'],
        ['Data Visualization:', 'Easy-to-understand dashboards help you make sense of complex data quickly.'],
        ['Automated Alerts and Reports:', 'Receive real-time alerts and customized reports to stay on top of what matters most.'],
        ['Predictive Maintenance:', 'Prevent breakdowns by predicting equipment issues in advance, saving time and costs.'],
        ['Operational Efficiency:', 'Use data insights to reduce waste, save energy, and streamline processes across your business.'],
        ['Data Security:', 'Your data is safe with us. We prioritize security and ensure compliance with all regulations.'],
      ],
      industries: [
        ['Smart Homes:', 'Monitor energy use, security, and appliances efficiently.'],
        ['Manufacturing:', 'Keep an eye on production and machinery health.'],
        ['Agriculture:', 'Track soil, weather, and crop conditions in real-time.'],
        ['Healthcare:', 'Monitor patients remotely and analyze health data.'],
        ['Logistics:', 'Optimize routes and stock levels with real-time tracking.'],
      ],
      
    },
  ]


  export const smartData = [
    {
      lorawan: [
        ['Dual Connectivity:', 'Supports both Ethernet and Wi-Fi, ensuring versatile network integration.'],
        ['LoRaWAN Compatibility:', 'Connects to a wide range of LoRaWAN devices for efficient data transmission.'],
        ['Backup Battery:', 'Equipped with a backup battery to ensure continuous operation during power outages.'],
        ['High Security:', 'Advanced encryption and secure communication protocols.'],
        ['Easy Setup and Management:', 'User-friendly interface for quick installation and remote management.'],
        ['Scalability:', 'Suitable for various applications, from small-scale deployments to large IoT networks.'],
      ],

      multiProtocol: [
        ['Dual Connectivity:', 'Supports both Ethernet and Wi-Fi, ensuring versatile network integration.'],
        ['LoRaWAN Compatibility:', 'Connects to a wide range of LoRaWAN devices for efficient data transmission.'],
        ['Backup Battery:', 'Equipped with a backup battery to ensure continuous operation during power outages.'],
        ['High Security:', 'Advanced encryption and secure communication protocols.'],
        ['Easy Setup and Management:', 'User-friendly interface for quick installation and remote management.'],
        ['Scalability:', 'Suitable for various applications, from small-scale deployments to large IoT networks.'],
      ],
    },

  ]

  export const grandeursmartMultiprotocolData = [
    {
      wireless: [
        ['Zigbee:', 'A low-power, reliable protocol perfect for home automation and smart lighting.'],
        ['Z-Wave:', 'Ideal for smart home devices, offering long-range, low-energy communication.'],
        ['Matter:', 'The future of smart home connectivity, ensuring interoperability across different brands and platforms.'],
        ['BLE (Bluetooth Low Energy):', 'Energy-efficient communication for short-range device interactions.'],
        ['LoRaWAN:', 'Long-range, low-power communication, suitable for industrial and wide-area applications.'],
        ['GPS:', 'For accurate location tracking and geofencing services.'],
        ['Wi-Fi:', 'High-speed, reliable wireless communication for real-time control and monitoring.'],
        ['LTE (Cat 4 and M1):', ' Cellular connectivity for remote monitoring and ensuring devices stay connected even without traditional internet access.'],
        ['MQTT:', 'A lightweight messaging protocol for machine-to-machine communication, perfect for IoT networks with minimal data requirements.'],
      ],

      wired: [
        ['Ethernet:', 'Reliable, fast, and secure wired communication for mission-critical operations, ensuring uninterrupted performance.'],
      ],
    },

  ]


  export const grandeurSmartServices = [
    {
      keyFeatures: [
        ['Unified Mobile App & Hub', 'Manage up to 100,000 devices from different manufacturers with ease—all through one intuitive app.'],
        ['Backup Battery', 'Keep everything running smoothly during power outages. The backup battery ensures your smart systems stay operational.'],
        ['Alternative Internet', 'No internet? No problem. With SIM card (LTE Cat 4 and M1) and Ethernet options, you’ll stay connected even when your primary internet goes down.'],
        ['Local Control & Privacy', 'Your data stays private. GrandeurSmart uses edge computing, so your devices are controlled locally, improving response times and protecting your data from the cloud.'],
        ['Offline Functionality', 'Enjoy uninterrupted smart home control even when your internet connection is offline.'],
        ['Edge Computing', 'Speed matters. Edge computing processes data locally for fast, real-time responses. Perfect for tasks like industrial automation and security where delays aren’t an option.'],
        ['AI and Voice Assistant Integration', 'Control your devices hands-free with Alexa, Google Assistant, Siri, or SMART—whichever suits your style.'],
        ['GPS & Location Tracking', 'Need location-based services? With built-in GPS, you can automate tasks based on your current location, like locking doors when you leave the house.'],
        ['Advanced Cybersecurity', 'With single cloud infrastructure and secure encryption, your data is safe from threats.'],
        ['Scalable Flexibility', 'From homes to large-scale buildings, the Gateway adapts to your needs, making it perfect for any environment.'],
      ],
      benefits: [
        ['Energy Efficiency:', 'Automate your heating, cooling, and lighting based on real-time usage, reducing energy waste and saving you money.'],
        ['Security:', 'Stay in control of your home or business security with real-time alerts from smart locks, cameras, and motion sensors.'],
        ['Uninterrupted Connectivity:', 'With backup battery and LTE internet options, your smart systems remain online, even during outages.'],
        ['Centralized Control:', 'Manage everything from lights and thermostats to security systems—all from one app, simplifying your smart device management.'],
        ['Improved Performance with Edge Computing:', 'Faster response times and better reliability thanks to local processing, especially in mission-critical tasks like security or industrial automation.'],
        ['Scalability:', 'GrandeurSmart Gateway can scale with your needs, whether its managing a single home, a large estate, or multiple commercial properties. Add new devices or systems seamlessly as your environment grows.'],
        ['Personalized Automation:', 'Create custom automation routines that suit your lifestyle. For example, the lights dim automatically when you’re ready for bed, or the thermostat adjusts when you leave the house.'],
        ['Data Privacy and Security:', 'With local control and secure data handling, your personal information and device data remain private, protected from cloud vulnerabilities.'],
        ['Remote Access:', 'Control and monitor your smart home or business from anywhere in the world. Whether you’re traveling or at work, you can check on your systems and make adjustments remotely.'],
        ['Energy Savings Insights:', 'Track your energy consumption in real-time and receive actionable insights on how to reduce your usage. This feature can help you save on utility bills while reducing your carbon footprint.'],
        ['Seamless Integration with Multiple Devices:', 'GrandeurSmart supports devices from various manufacturers, giving you the flexibility to build a smart system that works with your preferred devices and brands.'],
        ['Automation for Elderly Care:', 'Set up safety routines and monitoring for elderly family members, such as automatic alerts if no motion is detected for a period of time or reminders for medication.'],
      ],
      
    },
  ]


  export const smartBenefitData = [
    {
      name: 'Humidity Sensor',
      description1: ['Our advanced tracking devices ensure the security and real-time location tracking of your valuables, vehicles, and bicycles.', 
        'other description if available'
      ],
	    description2: ['Real-Time Location Tracking: Precise GPS tracking for keys, cars, and bicycles.',
        'Geo-Fencing: Set virtual boundaries and receive alerts when the device moves beyond them.', 
        'Battery Life: Long-lasting battery with power-saving modes.', 
        'Mobile App Integration: Easily track and manage devices via our mobile app.', 
        'Anti-Theft Alarms: Immediate alerts in case of unauthorized movement.',
      ],
      benefitDes: "Our SMART Products focus on delivering high-performance, real-time solutions. Benefits include:",
      benefits: [
        ['Reliable & uninterrupted connectivity for critical IoT applications', 'Ensures continuous operation and monitoring of devices, which is crucial for applications like healthcare monitoring, industrial automation, and smart cities.'],
        ['Flexibility in network integration & device compatibility', 'Supports a wide range of devices and network types, making it easier to integrate different technologies and expand IoT ecosystems without significant infrastructure changes.'],
        ['Enhanced security features for data protection', 'Protects sensitive data from cyber threats and unauthorized access, maintaining the integrity and confidentiality of the information transmitted by IoT devices.'],
        ['Simplified installation and management process.', 'Reduces the complexity and time required to set up and maintain IoT systems, allowing for quicker deployment and easier scaling of IoT solutions.']
      ],
      images: [
        `${pics.gatewayNew1}`,
        `${pics.gatewayNew2}`,
        `${pics.gatewayNew3}`,
        `${pics.gatewayNew4}`,
        
      ],

    },

    {
      name: 'Humidity Sensor',
      description1: ['Our advanced tracking devices ensure the security and real-time location tracking of your valuables, vehicles, and bicycles.', 
        'other description if available'
      ],
	    description2: ['Real-Time Location Tracking: Precise GPS tracking for keys, cars, and bicycles.',
        'Geo-Fencing: Set virtual boundaries and receive alerts when the device moves beyond them.', 
        'Battery Life: Long-lasting battery with power-saving modes.', 
        'Mobile App Integration: Easily track and manage devices via our mobile app.', 
        'Anti-Theft Alarms: Immediate alerts in case of unauthorized movement.',
      ],
      benefitDes: "Our SMART Products focus on delivering high-performance, real-time solutions. Benefits include:",
      benefits: [
        ['Reliable & uninterrupted connectivity for critical IoT applications', 'Ensures continuous operation and monitoring of devices, which is crucial for applications like healthcare monitoring, industrial automation, and smart cities.'],
        ['Flexibility in network integration & device compatibility', 'Supports a wide range of devices and network types, making it easier to integrate different technologies and expand IoT ecosystems without significant infrastructure changes.'],
        ['Enhanced security features for data protection', 'Protects sensitive data from cyber threats and unauthorized access, maintaining the integrity and confidentiality of the information transmitted by IoT devices.'],
        ['Simplified installation and management process.', 'Reduces the complexity and time required to set up and maintain IoT systems, allowing for quicker deployment and easier scaling of IoT solutions.']
      ],
      images: [
        `${pics.gatewayNew1}`,
        `${pics.gatewayNew2}`,
        `${pics.gatewayNew3}`,
        `${pics.gatewayNew4}`,
        
      ],

    },
  ]

  export const smartBenefit2Data = [
    {
        icon: faGlobe,
        heading: "Versatile connectivity options for a wide range of IoT devices.",
        content: "This ensures compatibility with various IoT devices, making it easier to integrate and manage different types of hardware within a single system.",
    },
    {
        icon: faGlobe,
        heading: "Continuous operation during power outages with reliable battery backup",
        content: "A reliable battery backup system guarantees that the IoT devices remain operational during power failures, ensuring uninterrupted service and data collection.",
    },
    {
        icon: faGlobe,
        heading: "Enhanced performance and security features.",
        content: "Improved performance ensures that the devices run efficiently, while robust security features protect the system from cyber threats and unauthorized access.",
    },
    {
        icon: faGlobe,
        heading: "Convenient remote management and monitoring",
        content: "This allows users to control and monitor their IoT devices from anywhere, providing flexibility and ease of use, especially for those managing multiple devices or systems.",
    },
    {
        icon: faGlobe,
        heading: "Ideal for smart homes, industrial applications",
        content: "The versatility and robustness of the solution make it suitable for various applications, from home automation to industrial IoT deployments, offering broad utility and scalability.",
    },
    
]

 
export const nfcData = [
  {
    nfcSolutions: [
      ['Google Review Tags:', 'Simplify the process for customers to leave reviews with a single tap.'],
      ['Social Media Following Tags:', 'Easy follow buttons for platforms like Facebook, Twitter, and Instagram.'],
      ['Contactless Payments:', 'Secure and fast transactions with NFC-enabled payment systems.'],
      ['Smart Business Cards:', 'Share contact information with a tap, eliminating the need for physical cards.'],
      ['Access Control:', 'Use NFC tags for secure entry to buildings or restricted areas.'],
    ],

    
  },

]


export const petData = [
  {
    analytic: [
      ['Dual Connectivity:', 'Supports both Ethernet and Wi-Fi, ensuring versatile network integration.'],
      ['LoRaWAN Compatibility:', 'Connects to a wide range of LoRaWAN devices for efficient data transmission.'],
      ['Backup Battery:', 'Equipped with a backup battery to ensure continuous operation during power outages.'],
      ['High Security:', 'Advanced encryption and secure communication protocols.'],
      ['Easy Setup and Management:', 'User-friendly interface for quick installation and remote management.'],
      ['Scalability:', 'Suitable for various applications, from small-scale deployments to large IoT networks.'],
    ],
  },

]

export const petAccessoriesData = [
  {
      img: `${pic.pet3}`,
      heading: "Strap, Rope, Clothes, Feeder",
      content: "High-quality, durable accessories for your pets",
  },
  {
      img: `${pic.pet4}`,
      heading: "Anti-Barking / Trainer App Control",
      content: "Manage and train your pets effectively",
  },
  {
      img: `${pic.pet5}`,
      heading: "Solar Power Option",
      content: "Sustainable and eco-friendly power solution",
  },
  {
      img: `${pic.pet6}`,
      heading: "Sound Tracking",
      content: "Monitor your pet's health through sound analytics",
  },
  {
      img: `${pic.pet7}`,
      heading: "Temperature Monitoring",
      content: "Ensure your pet’s environment is safe & comfortable",
  },
  {
    img: `${pic.pet8}`,
    heading: "Health Records and Analytics",
    content: "Keep track of your pet's health history and vet records",
},
]


export const grandeursmartUseCasesData = [
    
  {
    id: 1,
    name: 'Smart Home Automation, Intelligent Control, and Monitoring',
    description: [
      'Automate your lighting, heating, and cooling based on your schedule or real-time conditions. Set up monitoring alerts for things like energy use or motion detection.',  
    ],
    benefits: [
      'Convenient, hands-free control of your home environment.',
      'Reduce energy bills by optimizing device usage.',
      'Keep your home safe with real-time alerts for unexpected activity.',
    ],
    
    images: [
      `${pics.grandeurSmartHome}`,
    
    ],
  },
  {
    id: 2,
    name: 'Centralized Control for Smart Estate and Community',
    description: [
      'Easily manage multiple homes or a full estate, including access control, energy management, and security—all from one dashboard.',  
    ],
    benefits: [
      'Enhance security with centralized monitoring of multiple properties.',
      'Reduce energy waste with estate-wide energy management.',
      'Convenient remote management for smart systems across multiple units.',
    ],
    
    images: [
      `${pics.grandeurSmartEstate}`,
      
    ],
  },
  {
    id: 3,
    name: 'Edge IoT for Real-Time Data Processing',
    description: [
      'For industries requiring fast decision-making, GrandeurSmart’s edge computing processes data locally, ensuring quick, reliable responses.',  
    ],
    benefits: [
      'Reduced latency for real-time applications.',
      'Enhanced security by keeping sensitive data local.',
      'Ideal for industries like manufacturing and agriculture that rely on real-time monitoring.',
    ],
    
    images: [
      `${pics.grandeurConnectedWorld}`,
    
    ],
  },
  {
    id: 4,
    name: 'Asset Tracking, Management and Automation',
    description: [
      'Keep track of inventory, automate lighting, and monitor security cameras to optimize retail operations.',  
    ],
    benefits: [
      'Improve security with real-time asset tracking.',
      'Reduce energy consumption with smart lighting and HVAC control.',
      'Simplify inventory management through automation.',
    ],
    
    images: [
      `${pics.introBg}`,
      `${pics.temp3}`,
    ],
  },
  {
    id: 5,
    name: 'Remote Patient Monitoring (RPM)',
    description: [
      'Monitor patients remotely with real-time health data, improving response times and patient care.',  
    ],
    benefits: [
      'Immediate alerts for abnormal health readings.',
      'Reduce hospital visits with remote monitoring.',
      'Provide better, more personalized care for patients.',
    ],
    
    images: [
      `${pics.grandeurRpm}`,
      
    ],
  },
  {
    id: 6,
    name: 'LED Grow Light Control',
    description: [
      'The GrandeurSmart Gateway can be customized for LED light automation, letting you control and schedule lighting remotely for any space.',  
    ],
    benefits: [
      'Automate light cycles to save energy.',
      'Adjust settings easily from anywhere.',
      'Create personalized lighting environments.',
    ],
    
    images: [
      `${pics.grandeurLedLight}`,
    ],
  },
  {
    id: 6,
    name: 'Agriculture Automation',
    description: [
      'Automate temperature, humidity, and irrigation for optimal farming conditions with real-time monitoring.',  
    ],
    benefits: [
      'Boost crop yields with precise control.',
      'Save resources with automated climate management.',
      'Use data insights to improve farming efficiency.',
    ],
    
    images: [
      `${pics.grandeurAgric}`,
     
    ],
  },
  {
    id: 7,
    name: 'Active Drone Swarm Control',
    description: [
      'Manage multiple drones simultaneously for tasks like surveillance, agriculture, or logistics.',  
    ],
    benefits: [
      'Efficiently coordinate large fleets for specific tasks.',
      'Real-time data collection and analysis for improved operations.',
      'Perfect for industries that need precision drone control.',
    ],
    
    images: [
      `${pics.grandeurActiveDrone}`,
      
    ],
  },
  {
    id: 8,
    name: 'Custom IoT Solutions For Raspberry Pi/BeagleBone Alternative',
    description: [
      'Develop advanced IoT projects with the power and flexibility of GrandeurSmart, providing an alternative to platforms like Raspberry Pi or BeagleBone.',  
    ],
    benefits: [
      'A more powerful, scalable platform for developing custom IoT solutions.',
      'Wide compatibility with sensors and devices.',
      'Suitable for both hobbyists and professional developers.',
    ],
    
    images: [
      `${pics.techTools}`,
      
    ],
  },
];

export const cardsData = [
  {
    title: "Expert Team",
    description: "Our team consists of highly skilled professionals with years of experience in the industry, delivering exceptional results for our clients.",
    image: `${pics.service2}`,
  },
  {
    title: "Innovative Solutions",
    description: "We employ cutting-edge technologies and methodologies to provide innovative solutions that meet the unique needs of each project.",
    image: `${pics.gatewayNew1}`,
  },
  {
    title: "Customer Satisfaction",
    description: "We prioritize customer satisfaction by delivering quality work, maintaining transparency, and fostering long-term relationships with our clients.",
    image: `${pics.service4}`,
  }
];