import React from "react";
import { grandeurPet, pet3, } from "../../assets";
import { Link } from "react-router-dom";
import { petIotData } from "../../data/data4";

export default function PetIotSolution(){
    return (
        <div className="bg-white smd:mx-3 mt-10 mx-9">
            <div>
                <h1>Our Solutions</h1>
                <p className="text-center smd:px-3 px-[30%] pb-0">
                Our solutions drive transformative innovation, combining cutting-edge IoT technology with advanced automation to deliver unparalleled efficiency, real-time insights, and sustainable growth.
                </p>
                <div className="grid smd:grid-cols-1 lg:grid-cols-[0.7fr_0.3fr] items-center gap-4 py-5">
                    <div className="flex justify-center rounded-lg bg-white">
                        <img 
                            src={grandeurPet}
                            className="rounded-xl 2xl:rounded-3xl object-fill" 
                            alt="Our solutions" 
                        />
                    </div>

                    <div>
                        <h3 className="leading-normal text-black py-5">
                            Pet IoT Solutions
                        </h3>
                        
                        <div className="grid gap-6 smd:py-4 md:grid-cols-2 mb-5">
                            {petIotData.map((card, index) => (
                                <div key={index}>
                                
                                    <div className="grid grid-cols-[0.3fr_0.7fr] rounded-lg border-[1px] border-gray-300 hover:shadow-xl transition-shadow duration-500 overflow-hidden mb-5 transform hover:scale-105 h-full">
                                        
                                        <div className="h-full">
                                            <img 
                                                src={card.image} 
                                                alt="pet" 
                                                className="w-full h-full object-cover border-r-[1px] border-gray-300" 
                                            />
                                        </div>
                                        
                                        <div className="p-4 flex flex-col justify-center h-full">
                                            <h3 className="text-center leading-normal md:text-sm lg:text-sm 2xl:text-lg 3xl:text-2xl">
                                                {card.title}
                                            </h3>
                                            <p className="text-center">
                                                {card.des}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        
                        <Link to="/pets-iot">
                            <button className='button-m text-center'>Learn More</button>
                        </Link>
                    </div>


                </div>
                


    
            </div>

        </div>
    )
}
