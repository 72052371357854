import { useEffect } from 'react';
import axios from 'axios';

const GeolocationRedirect = () => {
  useEffect(() => {
    const fetchGeolocationData = async () => {
      try {
        
        if (!process.env.REACT_APP_IPGEOLOCATION_API_KEY) {
          throw new Error('API key is not set');
        }

        const response = await axios.get('https://api.ipgeolocation.io/ipgeo', {
          params: {
            apiKey: process.env.REACT_APP_IPGEOLOCATION_API_KEY,
          },
        });

        const { continent_name, country_name } = response.data;
        console.log('Continent:', continent_name, 'Country:', country_name);

        // Redirect based on continent or country
        if (continent_name === 'Africa') {
          window.location.replace('https://www.mainlogix.io');
        } else {
          switch (country_name) {
            case 'Egypt':
              window.location.replace('https://www.grandeursmart.skillzity.com');
              break;
            // Add other specific countries as needed
            default:
              console.log('Country not matched:', country_name);
          }
        }
      } catch (error) {
        console.error('Error fetching geolocation data:', error);
      }
    };

    fetchGeolocationData();
  }, []);

  return null; 
};

export default GeolocationRedirect;
