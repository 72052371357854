import React from "react";
import { Link } from "react-router-dom";
import { appAndriod, appApple, logo } from "../../assets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF } from '@fortawesome/free-brands-svg-icons'; 

export default function Footer2() {
    return (
        <div className="py-12 ">
            <div className="grid pt-3 smd:grid-cols-1 md:grid-cols-4 lg:grid-cols-4 gap-2 smd:flex smd:flex-col-reverse smd:gap-6">

                <div>
                    <Link to='/'>
                    <img 
                        src={logo} 
                        alt="Mainlogix" 
                       
                        className="smd:h-[50px] smd:w-auto h-[100px] w-[134px]" 
                    />
                    <article className="font-roboto smd:text-[12px] text-[14px] 2xl:text-3xl text-black">2024 mainlogix.ca</article>
                    </Link>
                </div>
                
                <div className="lg:border-l-[1px] border-gray-300 smd:pl-0 smd:gap-6 pl-2">
                    <h2 className="font-poppins text-red-600 smd:text-[12px] text-[14px] 2xl:text-3xl font-semibold">ABOUT US </h2>
                    <ul className="list-none">
                        <Link to='/contact'>
                            <li className="font-roboto smd:text-[12px] text-[14px] 2xl:text-3xl text-black mt-1">
                                Contact & Consult
                            </li>
                        </Link>
                        <Link to='/technologies-tools'>
                            <li className="font-roboto smd:text-[12px] text-[14px] 2xl:text-3xl text-black mt-1">
                                Technologies & Tools
                            </li>
                        </Link>
                    </ul>
                    <h2 className="font-roboto text-red-600 smd:text-[12px] text-[14px] 2xl:text-3xl font-semibold py-3">SOCIAL LINKS </h2>

                    <div className="flex items-center">
                        <a href="https://m.facebook.com/">
                            <FontAwesomeIcon 
                                icon={faFacebookF} 
                                size="1x" 
                                color="white" 
                                className="bg-red-600 rounded-full px-3.5 py-3" 
                            />
                        </a>
                        <a href="https://www.linkedin.com/company/mainlogix-technology-canada/?originalSubdomain=ca" target="_blank" rel="noopener noreferrer" className="inline-block ml-2">


                            <div className=" rounded-full py-3 px-3 bg-red-600">
                            

                                <svg 
                                    xmlns="http://www.w3.org/2000/svg" 
                                    width="15" 
                                    height="auto" 
                                    viewBox="0 0 24 24"
                                    fill="#FFFFFF"
                                    
                                    
                                >
                                    <path 
                                        d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z"    
                                    />
                                </svg>

                            </div>
                        </a>
                    </div>
                    <div >
                    <div className="flex items-center justify-start gap-1 flex-row mr-10 pt-3" >
                      <div>
                      <img src={appApple} alt="" className="" />
                      </div>
                      <div>
                      <Link to='https://play.google.com/store/apps/details?id=com.grandeursmart.home.android'>
                        <img src={appAndriod} alt="" className=""/>
                      </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div >
                    <h2 className="font-poppins text-red-600 smd:text-[12px] text-[14px] 2xl:text-3xl font-semibold">QUICK LINKS </h2>
                    <ul className="list-none">
                        <Link to='/iot-development-services' >
                        <li className="font-roboto smd:text-[12px] text-[14px] 2xl:text-3xl text-black mt-1">
                            IoT Development Services
                        </li>
                        </Link>
                        <Link to='/general-features' >
                        <li className="font-roboto smd:text-[12px] text-[14px] 2xl:text-3xl text-black mt-1">
                            General Features
                        </li>
                        </Link>
                        <Link to='/operating-system-development' >
                        <li className="font-roboto smd:text-[12px] text-[14px] 2xl:text-3xl text-black mt-1">
                            Operating System Development
                        </li>
                        </Link>
                        <Link to='/existing-solutions' >
                        <li className="font-roboto smd:text-[12px] text-[14px] 2xl:text-3xl text-black mt-1">
                            Existing Solutions
                        </li>
                        </Link>
                        <Link to='/iot-product-development-step' >
                        <li className="font-roboto smd:text-[12px] text-[14px] 2xl:text-3xl text-black mt-1">
                            IoT Product Development Steps
                        </li>
                        </Link>
                        <Link to='/smart' >
                            <li className="font-roboto smd:text-[12px] text-[14px] 2xl:text-3xl text-black mt-1">
                                Smart by Mainlogix
                            </li>
                        </Link>
                    </ul>
                </div>
                <div>
                    <h2 className="font-poppins text-red-600 smd:text-[12px] text-[14px] 2xl:text-3xl font-semibold">LINKEDIN FEEDS</h2>
                    <div>

                        <iframe src='https://widgets.sociablekit.com/linkedin-page-posts/iframe/25463894' frameborder='0' width='90%' height='100%' title="Embedded post"></iframe>

                    </div>
                    
                </div>
            </div>
        </div>
    );
}
