import React, { useState } from "react";
import { service1 } from "../../assets";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function FollowUs(){
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        fetch('http://144.126.154.36:3000/newsletter', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email })
        })
        .then(res => res.json())
        .then(data => {
            if (data.success) {
                toast.success('Successfully subscribed!', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                // Reset form data
                setEmail('');
                setMessage('');
            } else {
                toast.success('Successfully subscribed!', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                // Reset form data
                // Reset form data
                setEmail('');
                setMessage('');
            }
        })
        .catch(error => {
            setMessage('Error Subscribing');
            toast.error('Error Subscribing'); // Show general error notification
        });
    };




    return(
        <div>
            <div className="relative w-full ">
                <div
                    className="absolute inset-0 bg-cover bg-center"
                    style={{ backgroundImage: `url(${service1})` }}
                >
                    <div className="absolute inset-0 bg-black bg-opacity-75"></div>
                </div>
                
                <div className="relative flex flex-col justify-center h-full smd:px-3 px-[25%] smd:py-10 py-20 text-center">
                    <h1 className="text-white pb-4 text-center">Follow Us & Subscribe Our Newsletter</h1>
                    <div className="flex justify-center">
                    <form 
                        onSubmit={handleSubmit}
                        className="flex items-center justify-center">
                        <input 
                            type="email" 
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            name="email" 
                            placeholder="Enter email..." 
                            required 
                            className="font-poppins text-white border-solid border-[2px] rounded-tl-full rounded-bl-full px-4 py-2 bg-transparent smd:text-[10px] text-[15px] 2xl:text-3xl" />
                        <button type="submit" className="bg-red-600 rounded-tr-full rounded-br-full ml-[-5px] smd:py-1 px-4 py-2 font-poppins font-medium smd:text-[10px] text-lg 2xl:text-3xl 2xl:leading-normal text-white">SUBSCRIBE</button>
                    </form>
                    </div>
                    {message && <p>{message}</p>}
                </div>

            </div>

            <ToastContainer />
        </div>
    )
}
