import React from 'react';
import { InlineWidget } from 'react-calendly';
import '../BookACall.css';
import '@fortawesome/fontawesome-free/css/all.min.css';


const BookACall = () => {
  const handleButtonClick = () => {
    const calendlyUrl = 'https://calendly.com/josiah-mainlogix/mainlogix-technology-canada';
    window.Calendly.initPopupWidget({ url: calendlyUrl });
    return false;
  };

  return (
        <div className="book-a-call" onClick={handleButtonClick}>
          <div className="click-book" >
            <i className="fas fa-phone-alt"></i>
          </div>
          <p className="book-call">Book A Call</p>
        </div>
      );
};

export default BookACall;