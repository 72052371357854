import React from "react";
import { nfcBg } from "../../assets";
import { nfcData } from "../../data/data1";
import { Link } from "react-router-dom";

export default function Nfc1(){
    return (
        <div className="bg-white mt-10 smd:mx-3 mx-9">
            <div>
                
                <div className="grid smd:grid-cols-1 smd:flex smd:flex-col-reverse grid-cols-[1.3fr_0.7fr] items-center gap-4 py-5">
                    <div>
                        <h3 className="leading-normal text-red-600">NFC Solutions for Various Use Cases</h3>
                        <article className="font-roboto smd:px-0 px-[16px] pb-2 2xl:text-3xl">
                            Leverage the power of NFC technology with our 
                            versatile solutions for different applications, 
                            including:
                        </article>
                        
                        <div className="flex pt-0 pb-5 gap-2 items-start justify-between">
                            <ul className="list-disc px-6 2xl:text-3xl">
                                {nfcData.map((item, index) => (
                                    item.nfcSolutions.map((smart, subIndex) => ( 
                                        <li key={`${index}-${subIndex}`} className="leading-7 2xl:leading-10">
                                            <strong>{smart[0]}</strong> {smart[1]}
                                        </li>
                                    ))
                                ))}
                            </ul>
                        </div>
                        <Link to="/nfc">
                            <button className='button-m text-center'>Learn More</button>
                        </Link>   
                    </div>

                    <div className="flex justify-center rounded-lg bg-gray-200">
                        <img 
                            src={nfcBg}
                            className="rounded-xl w-[100%] object-fill" 
                            alt="Our solutions" 
                        />
                    </div>

                </div>
                


    
            </div>

        </div>
    )
}
