import React from 'react'
import { introBg } from '../../assets'



const Hero = () => {

  return (
    <div>

      <div className="relative w-full ">
        <div
            className="absolute inset-0 bg-cover bg-center"
            style={{ backgroundImage: `url(${introBg})` }}
        >
            <div className="absolute inset-0 bg-black bg-opacity-75"></div>
        </div>
        
        <div className="relative flex flex-col justify-center h-full smd:px-3 px-[25%] smd:py-10 py-20 text-center">
            <h2 className="title-text font-poppins font-semibold text-[55px] text-white">
              About Us
            </h2>
            <p className="text-center text-white mt-4">
            Mainlogix Technology is a leading innovative company specializing in smart home automation, IoT solutions, and advanced technology services. We develop cutting-edge products that enhance everyday living, focusing on areas like Smart Home Automation, Remote Patient Monitoring, Telemedicine, Mobile Health, Agric IoT, and Smart City Solutions. With a mission to transform how people interact with technology, Mainlogix blends innovation with sustainability to deliver intelligent, efficient, and user-
            -friendly solutions tailored to both businesses and individuals. Our vision is to create a seamless, interconnected future driven by smart technology.
            </p>
        </div>
      </div>
    </div>
  )
}

export default Hero
